import * as React from 'react'

import { useSiteConfig } from '@thg-commerce/enterprise-core'
import { ProductImageUrls } from '@thg-commerce/gravity-elements'
import { LocationCircle } from '@thg-commerce/gravity-icons/src'
import { ProductBlock } from '@thg-commerce/gravity-patterns/ProductBlock'
import { styled } from '@thg-commerce/gravity-patterns/theme'
import { spacing, Text } from '@thg-commerce/gravity-theme'

export interface ProductSearchProps {
  productsTitle: string
  products: Product[]
  currentIndex: number
  selectedRef: any
  fromText: string
  rrpText?: string
  freeText?: string
  movePastInput: () => void
  inputValue: string
  showPowerReview: boolean
}

interface Reviews {
  averageScore: number
  total?: number
  screenReaderOnlyText: string
}

export interface Product {
  title: string
  images: ProductImageUrls[]
  price: {
    price: {
      displayValue: string
    }
    hasFromPrice: boolean
  }
  options?: string
  variants?: {
    price: {
      price: string
    }
  }[]
  defaultVariant?: string
  url: string
  reviews: Reviews
  platform?: string
}

const AllProductsContainer = styled.div`
  background-color: ${(props) => props.theme.colors.palette.greys.white};
  margin-top: ${spacing(1)};
  padding: ${spacing(1)} 0;
`

const ProductContainer = styled.li<{ isSelected: boolean }>`
  position: relative;
  margin: 0;
  overflow: hidden;

  ${(props) =>
    props.isSelected &&
    `
      background-color: ${props.theme.colors.palette.greys.lighter};
      display: flex;
      justify-content: space-between;
    `}
`
const CircleWrapper = styled.div`
  display: flex;
  position: absolute;
  height: 100%;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  padding-right: ${spacing(2)};
`

const ProductsTitle = styled.p`
  ${(props) =>
    Text(
      props.theme.patterns.header.search.dropdownHeaderFont.entry,
      props.theme.patterns.header.search.dropdownHeaderFont.style,
    )};
  ${(props) => `
    text-decoration: ${props.theme.patterns.header.search.dropdownHeaderFont.textDecoration};
    text-transform: ${props.theme.patterns.header.search.dropdownHeaderFont.transform};
    color: ${props.theme.patterns.header.search.dropdownHeaderFont.textColor};
  `}
  padding: ${spacing(2)};
`

const StyledLocationCircle = styled(LocationCircle)`
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  g {
    fill: ${(props) => props.theme.colors.palette.brand.base};
  }
`

const StyledProductBlock = styled(ProductBlock)`
  padding: ${spacing(2)};
  border: 2px solid transparent;

  &:hover,
  &:active,
  &:focus-visible {
    background-color: ${(props) => props.theme.colors.palette.greys.lighter};
  }

  &:focus-visible {
    border: 2px solid ${(props) => props.theme.colors.palette.brand.base};
  }
`

export const ProductSearchResults = (props: ProductSearchProps) => {
  const { hideSearchPrice } = useSiteConfig()

  const Products = () => {
    return props.products.map((product, index) => {
      const isSelected = props.currentIndex === index
      const review =
        product.reviews.averageScore !== 0 || product.reviews.total !== 0
          ? {
              starRating: product.reviews.averageScore,
              ...product.reviews,
            }
          : null
      return (
        <ProductContainer
          key={`product ${product.title}`}
          isSelected={isSelected}
          ref={isSelected ? props.selectedRef : undefined}
          onKeyDown={
            index === props.products.length - 1
              ? (e: React.KeyboardEvent) => {
                  if (e.key === 'Tab' && !e.shiftKey) {
                    props.movePastInput()
                  }
                }
              : undefined
          }
        >
          <StyledProductBlock
            title={{ value: product.title, useAlternateStyle: false }}
            url={`${product.url}?autocomplete=productsuggestion&search=${props.inputValue}`}
            review={review}
            image={{
              urls: product.images[0],
              width: 70,
            }}
            directions={['row']}
            condensed
            price={{
              price: {
                defaultPrice: product.price.price.displayValue,
              },
              hasFromPrice: product.price.hasFromPrice,
              fromText: props.fromText,
              rrpText: props.rrpText,
              freeText: props.freeText,
            }}
            showPowerReview={props.showPowerReview}
            hideSearchPriceInHeader={hideSearchPrice}
          />
          {isSelected && (
            <CircleWrapper>
              <StyledLocationCircle />
            </CircleWrapper>
          )}
        </ProductContainer>
      )
    })
  }

  return (
    <AllProductsContainer>
      <ProductsTitle>{props.productsTitle}</ProductsTitle>
      <ul aria-label={props.productsTitle}>{Products()}</ul>
    </AllProductsContainer>
  )
}
